import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

import Skus from "../components/Products/Skateboards";

const Skateboards = () => (
  <Layout>
    <SEO
      keywords={[
        `SkateBoarding`,
        `Think Skateboards`,
        `Thrasher`,
        `1990's`,
        `1990s`,
        `90's`,
        `Skateboard`,
        `Blind Skateboards`,
        `VHS`,
        `video`,
        `skateboard`,
        `stickers`,
        `World Industries`,
      ]}
      title="Skateboards"
    />
    <h1 className="mt-1 font-semibold text-lg leading-tight truncate">
      Skateboards Stickers
    </h1>
    <p>
      90s skateboard stickers these are all OG stickers long out of print. Each
      sticker has some wear or slipage please look close to see details.
    </p>
    <Skus />
  </Layout>
);

export default Skateboards;
